import { defineStore } from 'pinia'

export const useFeatureStore = defineStore('FeatureStore', {
  state: () => {
    return {
      features: [],
    }
  },
  getters: {
    isFeatureEnabled: (state) => {
      return (feature) => state.features?.indexOf(feature) > -1
    },
  },
  actions: {
    setFeatures(features) {
      this.features = features
    },
  },
})
