import { render, staticRenderFns } from "./KeyStatBlock.vue?vue&type=template&id=8ea6232a&lang=pug"
import script from "./KeyStatBlock.vue?vue&type=script&lang=ts"
export * from "./KeyStatBlock.vue?vue&type=script&lang=ts"
import style0 from "./KeyStatBlock.vue?vue&type=style&index=0&id=8ea6232a&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/musashi/musashi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ea6232a')) {
      api.createRecord('8ea6232a', component.options)
    } else {
      api.reload('8ea6232a', component.options)
    }
    module.hot.accept("./KeyStatBlock.vue?vue&type=template&id=8ea6232a&lang=pug", function () {
      api.rerender('8ea6232a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/blocks/KeyStatBlock.vue"
export default component.exports