<template lang="pug">
  .modals
    modal(name='video_modal' width='600px' height='300' v-cloak)
      .ue_modal
        iframe(src='https://www.youtube.com/embed/10LBOaC-Kkk' width='100%' height='100%' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'  allowfullscreen mozallowfullscreen webkitallowfullscreen)
    modal(name='schedule_meeting_modal' width='350' height='600' v-cloak)
      .ue_modal
        vue-calendly(url='https://calendly.com/ray-213')
</template>
<script lang="ts">
export default {
  name: 'Modals',
  mounted() {
    // Events.$on('watch_video', this.$modal.show('video_modal'))
    // Events.$on('schedule_meeting', this.$modal.show('schedule_meeting'))
  },
}
</script>
