<template lang="pug">
  modal(name='share_customer_spotlight_modal' @before-open='beforeOpen' height='auto')
    .modal_container.modal_container--customer_spotlight(v-if='customer_spotlight' :class="{freestanding: freestanding}")
      .modal_header.mb-0
        h1.title.m-0 Download options
        .closer(@click='$modal.hide("share_customer_spotlight_modal")' v-if='!freestanding')
          TimesIcon
      .modal_body
        section.share-option
          b-form-group()
            //- b-form-radio(v-model="filetype" name="filetype-radios" value="png") PNG
            b-form-radio(v-model="filetype" name="filetype-radios" value="pdf") Single-page PDF

        .download.mt-1
          b-button(variant="primary" block disabled v-if="download_in_progress") Generating&hellip;
          b-button(variant="primary" block @click="onDownload" v-if="!download_in_progress") Download
</template>
<script lang="ts">
import TimesIcon from './graphics/TimesIcon'
import DownloadIcon from './graphics/DownloadIcon'
import LinkIcon from './graphics/LinkIcon'
import EmbedIcon from './graphics/EmbedIcon'
import api from '../app/mixins/api.js'
import { AxiosResponse } from 'axios'
import { pendoTrack } from '@lib/pendo'
import { amplitudeTrack } from '@lib/amplitude'
import ahoy from 'ahoy.js'
import { assetEventData } from '@lib/tracking'

export default {
  components: { TimesIcon, DownloadIcon, LinkIcon, EmbedIcon },
  mixins: [api],
  props: ['freestanding'],
  data() {
    return {
      filetype: 'pdf',
      download_in_progress: false,
      customer_spotlight: null,
      account: null,
    }
  },
  computed: {
    download_options() {
      return {
        filetype: this.filetype,
      }
    },
    eventData() {
      return {
        ...assetEventData(this.customer_spotlight),
        filetype: this.filetype,
      }
    },
  },
  methods: {
    beforeOpen(e) {
      this.customer_spotlight = e.params.customer_spotlight
      this.account = this.customer_spotlight.account
    },
    attemptPopUp(url: string) {
      const new_window = window.open(url, '_blank')
      try {
        new_window.focus()
      } catch (e) {
        // Trying to `window.open` a PDF attachment file seems to trigger pop-up blockers on Chrome
        alert(
          'It looks like a pop-up blocker prevented the download window from opening. Please allow them for this site.',
        )
      }
    },
    onDownload() {
      pendoTrack('download_asset', this.eventData)
      amplitudeTrack('asset downloaded', this.eventData)
      ahoy.track('asset downloaded', this.eventData)
      this.download_in_progress = true
      this.renderCustomerSpotlight(this.customer_spotlight.id, this.download_options)
        .then((r: AxiosResponse) => {
          return r.data.download_url
        })
        .then((url: string) => this.attemptPopUp(url))
        .catch(() => alert('Darn! Something went wrong. Please try again.'))
        .finally(() => (this.download_in_progress = false))
    },
  },
}
</script>
