<template>
  <div class="matrixx_guts">
    <div class="legend">
      <div v-for="(legend_item, i) in legendItems" :key="legend_item" class="legend-item">
        <div class="legend-item__color" :style="{ backgroundColor: colorForSegment(i) }"></div>
        <span class="legend-item__label">{{ legend_item }}</span>
      </div>
    </div>
    <div class="rows">
      <div v-for="row in rows" :key="row.label" class="row-wrapper text-left text-break">
        <div class="row__segments">
          <MatrixxChartSegmentBasic
            v-for="(segment, i) in row.segments"
            :key="segment.label"
            :segment="segment"
            :fill-color="colorForSegment(i)"
          ></MatrixxChartSegmentBasic>
        </div>
        <div class="row__label">{{ row.label }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MatrixxChartSegmentBasic from './MatrixxChartSegmentBasic.vue'
import { brandColorSchemeForColor } from '@lib/colors'

export default {
  components: { MatrixxChartSegmentBasic },
  props: {
    matrixxChart: {
      type: Object,
      required: true,
    },
    // account is used by mixins
    account: {
      type: Object,
      required: true,
    },
    colorChoice: {
      type: String,
    },
  },
  computed: {
    legendItems() {
      if (this.matrixxChart.answer_stats.length === 0) return []
      return this.matrixxChart.answer_stats[0].answer_stats.map((stat) => {
        return stat.the_answer
      })
    },
    rows() {
      if (this.matrixxChart.answer_stats.length === 0) return []
      return this.matrixxChart.answer_stats.map((mr) => {
        const total_responses = mr.effective_response_count
        return {
          label: mr.the_question,
          segments: mr.answer_stats.map((stat) => {
            return {
              label: stat.the_answer,
              percentage: Math.round((stat.count / total_responses) * 100),
            }
          }),
        }
      })
    },
    segmentCount() {
      return this.rows[0].segments.length
    },
  },
  methods: {
    segmentStyles(segment) {
      return {
        flexBasis: `${segment.percentage}%`,
      }
    },
    colorForSegment(segmentNumber) {
      return this.brandColorSchemeForColor(this.colorChoice, this.segmentCount)[segmentNumber]
    },
    brandColorSchemeForColor,
  },
}
</script>

<style lang="sass" scoped>

.as_list
  .legend-item
    font-size: 12px

.legend
  margin-top: 1em
.legend-item
  line-height: 16px
  font-size: 14px
  font-weight: 500
  display: inline-block
  white-space: nowrap
  margin-right: .75em

.legend-item__color
  width: .8em
  height: .8em
  border-radius: 15%
  display: inline-block

.legend, .row__label
  font-size: .75em

.rows
  width: 100%

.row__label
  font-weight: 400
  font-size: 12px
  line-height: 15px
  padding-top: 0.2em

.row__segments
  display: flex
  width: 100%
  align-items: center
  padding-top: 1em

::v-deep .segment
  height: 32px
  display: flex
  align-items: center
  overflow: hidden
  .segment__label
    font-size: 12px
    margin-left: .5em
    font-weight: 800
  &:last-child
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
</style>
