<template lang="html">
  <div
    class="branded_style_asset bar_chart_branded px-4"
    :class="{ 'brand-font-enabled': brandFont }"
    :style="bothBackgroundCssVars"
  >
    <div class="container pt-3 pb-3 brand-font">
      <BrandedHeader
        :logoSvg="account.svg_logo_mark"
        :text="headerText"
        :logoSizeOption="logoSizeOption"
      />

      <BarGutsBasic
        :stats="contentAsset.answer_stats"
        :total="contentAsset.effective_response_count"
        :account="account"
        :chartColor="chartColor"
      />
    </div>

    <BrandedFooter
      :verifiedAt="contentAsset.verified_at"
      :ueId="contentAsset.identifier"
      :color="footerColor"
      :surveySummary="brandedSourceText"
      class="mt-3"
    />
  </div>
</template>
<script lang="ts">
import BrandedAssetMixin from '@app/mixins/branded_asset.ts'
import AssetSourceMixin from '@app/mixins/asset_source'
import HeaderTextMixin from '@app/mixins/header_text'
import BarGutsBasic from './basic_asset_parts/bar_chart/BarGutsBasic.vue'
import BrandedFooter from '@components/branded_asset_parts/BrandedFooter.vue'
import BrandedHeader from '@components/branded_asset_parts/BrandedHeader.vue'
import { injectFontFamily } from '@lib/injectFont'

export default {
  name: 'BarChartBranded',
  components: { BarGutsBasic, BrandedFooter, BrandedHeader },
  mixins: [BrandedAssetMixin, AssetSourceMixin, HeaderTextMixin],
  props: {
    contentAsset: { type: Object, required: true },
    // This is the color that will be used to fill the chart bar
    chartColorOption: { type: String, default: 'primary' },
  },
  beforeMount() {
    if (this.brandFont) {
      injectFontFamily(this.brandFont)
    }
  },
  computed: {
    brandFont() {
      return this.account?.brand_font
    },
  },
  data() {
    return {
      account: this.contentAsset.account,
    }
  },
}
</script>

<style lang="sass" scoped>
.bar_chart_branded .container
  border-top-left-radius: 0
  border-top-right-radius: 0
</style>
