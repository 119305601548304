// This lives in it's own module / file because it's used in the Vue
// api mixin and also outside of Vue in the customer spotlight utilities.

import axios from 'axios'

const fetchSummary = (type, respondent_company, responses) => {
  return axios.post('/app/api/airops/summary', {
    type: type,
    respondent_company: respondent_company,
    responses: responses,
  })
}

export default fetchSummary
