<template>
  <div class="uevi_anchor">
    <a :href="shareLink" :style="{ color: brandLinkColor }"> uevi.co/{{ assetIdentifier }} </a>
  </div>
</template>

<script lang="ts">
import { brandLinkColor } from '@lib/colors'
import { generateShareLink } from '@lib/share_link'

export default {
  props: {
    assetIdentifier: {
      type: String,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shareLink() {
      return generateShareLink(this.assetIdentifier)
    },
    brandLinkColor,
  },
}
</script>

<style lang="sass" scoped>
.uevi_anchor a
  font-weight: 800
  font-size: 10px
  line-height: 1
  &:hover
    filter: brightness(0.75)
    text-decoration: none
</style>
