<template lang="pug">
  .typed_text_container
    span.typed_word {{ this.type_value }}
    span.cursor(:class={ typing: type_status })
    span .
</template>
<script>
export default {
  name: 'Ticker',
  data() {
    return {
      type_value: '',
      type_status: false,
      typeArray: ['testimonials', 'case studies', 'reviews', 'statistics'],
      typingSpeed: 120,
      erasingSpeed: 100,
      newTextDelay: 1500,
      typeArrayIndex: 0,
      charIndex: 0,
    }
  },
  mounted() {
    setTimeout(this.typeText, this.newTextDelay + 200)
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.type_status) this.type_status = true
        this.type_value += this.typeArray[this.typeArrayIndex].charAt(this.charIndex)
        this.charIndex += 1
        setTimeout(this.typeText, this.typingSpeed)
      } else {
        this.type_status = false
        setTimeout(this.eraseText, this.newTextDelay)
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.type_status) this.type_status = true
        this.type_value = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1)
        this.charIndex -= 1
        setTimeout(this.eraseText, this.erasingSpeed)
      } else {
        this.type_status = false
        this.typeArrayIndex += 1
        if (this.typeArrayIndex >= this.typeArray.length) this.typeArrayIndex = 0
        setTimeout(this.typeText, this.typingSpeed + 1000)
      }
    },
  },
}
</script>
