<template lang="html">
  <div class="basic-header">
    <div class="d-flex align-items-center">
      <span v-if="logoSvg" v-html="logoSvg" class="logo" aria-hidden></span>
    </div>

    <div v-if="text" class="text font-weight-bold mt-3">{{ text }}</div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    logoSvg: { type: String, required: true }, // all assets show a logo

    // this is usually either title or question, it is decided by the parent component
    text: { type: String, required: false },
  },
}
</script>
<style lang="sass" scoped>
.logo
  display: inline-block
  height: 24px
  ::v-deep svg
    width: 100%
    height: 100%
.text
  font-size: 1rem
  font-weight: 600
</style>
