<template lang="html">
  <div class="basic_style_asset matrixx_chart_basic" :class="{ as_list: renderAsList }">
    <div class="wrapper">
      <BasicHeader
        :logoSvg="account.svg_logo_mark"
        :text="matrixxChart.question_text"
        :surveySummary="sourceText"
      />
      <MatrixxGutsBasic
        :matrixxChart="matrixxChart"
        :account="account"
        :colorChoice="colorChoice"
      />
    </div>

    <BasicFooter
      :verifiedAt="matrixxChart.verified_at"
      :ueId="matrixxChart.identifier"
      :surveySummary="sourceText"
      class="mt-3"
    />
  </div>
</template>
<script lang="ts">
import AssetSourceMixin from '@app/mixins/asset_source'
import { getBasicColor } from '@lib/colors'
import MatrixxGutsBasic from './basic_asset_parts/matrixx_chart/MatrixxGutsBasic.vue'
import BasicFooter from '@components/basic_asset_parts/BasicFooter.vue'
import BasicHeader from '@components/basic_asset_parts/BasicHeader.vue'

export default {
  components: { BasicFooter, BasicHeader, MatrixxGutsBasic },
  mixins: [AssetSourceMixin],
  props: {
    matrixxChart: { type: Object, required: true },
    account: { type: Object, required: true },

    // This is the color that will be used to fill the segments of the matrixx chart.
    statColorOption: { type: String, default: 'primary' },
    renderAsList: { type: Boolean, required: false, default: false },
  },
  computed: {
    contentAsset() {
      return this.matrixxChart
    },
    colorChoice() {
      return this.getBasicColor(this.account, this.statColorOption)
    },
  },
  methods: {
    getBasicColor,
  },
}
</script>
<style lang="sass" scoped></style>
