<template>
  <iframe
    v-if="blobUrl"
    :src="blobUrl"
    frameborder="0"
    style="border-style: none; overflow: hidden; width: 100%; height: 100%"
  >
    <p>Your browser doesn't support viewing the PDF file, download it instead.</p>
  </iframe>
</template>
<script lang="ts">
export default {
  name: 'PdfViewer',
  props: {
    fileAsset: { type: Object, required: false },
    fileObject: {
      type: File,
      required: false,
    },
  },
  data() {
    return {
      blobUrl: null,
    }
  },
  beforeMount() {
    // creates a url from the file object itself, which happens when
    // the file is dropped on the dropzone
    if (this.fileObject) {
      this.blobUrl = URL.createObjectURL(this.fileObject)
      return
    }
    // creates a url from the fileAsset file_url that comes from the API
    // when the asset is already created
    this.blobUrl = `${process.env.VUE_APP_UE_APP_URL}${this.fileAsset.file_url}#toolbar=1&view=Fit&zoom=100`
  },
}
</script>
