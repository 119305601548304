<template lang="pug">
  .testimonial_highlight
    .left(:style='`background-color: ${account.primary_color};`')
    .highlights
      .testimonial(v-for='(testimonial, i) in testimonials' :class='{ single: single }')
        .quote
          UpsideDownQuoteIcon.quote1(:primary_color='account.primary_color' v-if='i == 0')
          h2 {{testimonial.text_answer}}
          QuoteIcon.quote2(:primary_color='account.primary_color' v-if='i == testimonials.length - 1')
        .attribution
          .avatar
            AvatarIcon(:style='avatar_circle_color')
          .name(v-if='testimonial.recipient.named')
            h3 {{testimonial.recipient.person_attribution}}
            h4 {{testimonial.recipient.title}}
            h4(v-if='!hide_company_attribution') {{testimonial.recipient.company_name}}
          .name(v-else)
            h4 {{testimonial.recipient.person_attribution}}
            h4(v-if='!hide_company_attribution') {{testimonial.recipient.company_attribution}}
    .right(:style='`background-color: ${account.primary_color};`')
</template>
<script lang="ts">
import UpsideDownQuoteIcon from './graphics/UpsideDownQuoteIcon'
import QuoteIcon from './graphics/QuoteIcon'
import AvatarIcon from './graphics/AvatarIcon'

export default {
  components: { UpsideDownQuoteIcon, QuoteIcon, AvatarIcon },
  props: {
    testimonials: {
      type: Array,
      default: () => [],
    },
    hide_company_attribution: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatar_circle_color() {
      return `box-shadow: 0 0 0 2px white, 0 0 0 4px ${this.account.primary_color};`
    },
    account() {
      return this.testimonials[0].account
    },
    single() {
      return this.testimonials.length == 1
    },
  },
}
</script>
<style lang="sass" scoped>
$svg-width: 24px
$quote-offset: 2rem
.testimonial_highlight
  padding: 0
  display: flex
  width: 100%
  align-items: center
  background: white
  .highlights
    display: flex
    flex-wrap: wrap
    width: 100%
    justify-content: space-between
    .testimonial
      flex-basis: calc(50% - 1.5rem)
    .testimonial:nth-child(odd):last-child
      flex-basis: 100%
    .testimonial:nth-child(n+3)
      margin-top: 3rem
  .right, .left
    flex-grow: 1
    height: 8px
    background: $uePurple
    top: 50%
    transform: translateY(-50%)
    display: block
    opacity: 0.5
  .left
    margin-left: -3000px // full bleed hack
    transform: translateX(-4 * $quote-offset)
  .right
    margin-right: -3000px // full bleed hack
    transform: translateX(4 * $quote-offset)

  .quote
    display: flex

    margin-bottom: 24px
  ::v-deep
    .quote1, .quote2
      width: $svg-width // this is set in the SVG attributes
      flex-shrink: 0 // prevent the svg resizing
      box-sizing: content-box
    .quote1
      align-self: flex-start
      margin-left: calc(-1 * ($svg-width + $quote-offset))
      padding-right: $quote-offset

    .quote2
      align-self: flex-end
      margin-right: calc(-1 * ($svg-width + $quote-offset))
      padding-left: $quote-offset
.testimonial
  // width: 100%
  // min-width: 48%
  h5
    line-height: 1
    margin: 0 0 16px 24px
    color: hsl(200, 16%, 45%)
    &:after
      display: none
  h2
    font-weight: 800
    font-size: 22px
    line-height: 140%
    letter-spacing: -0.01em
    color: #131516
  .attribution
    display: inline-flex
    flex-direction: row
    align-items: center
    .name
      display: flex
      flex-direction: column
      font-weight: 600
      font-size: 15px
      letter-spacing: -0.015em
      color: #48555B
      line-height: 1
      h3
        margin-bottom: 7px
      h4
        margin-bottom: 0px
        padding: 0
        line-height: 1.3
      h4:first-child
        margin-bottom: 4px
    img, svg
      width: 50px !important
      height: 50px
      border-radius: 50%
      margin-right: 16px

@media screen and (max-width: 1200px), print
  .testimonial_highlight
    border-left: none !important

@media screen and (max-width: 816px)
  .quote1, .quote2
    display: none
    visibility: hidden
    opacity: 0
  .testimonial_highlight
    padding: 0 32px 0 0
    display: flex
    .left
      display: block
      left: -32px
      width: 8px
      height: 100%
      opacity: 1
    .right
      display: none !important
      opacity: 0
  .testimonial
    &.single
      margin-left: 0px !important
  .testimonial h5
    padding: 0 !important
    .left, .right
      display: none

  .testimonial_highlight
    padding: 0px
    h5
      margin: 0px
    .highlights
      display: block
      .testimonial
        margin-bottom: 64px

@media print
  .testimonial_highlight
    page-break-inside: avoid
</style>
