export default {
  data() {
    return {
      company_filters: [],
      size_group_filters: [],
      sector_filters: [],
      seniority_filters: [],
      recipient_role_filters: [],
      survey_filters: [],
      volunteer_activity_filters: [],
      tag_filters: [],
    }
  },
  computed: {
    the_size_group_filters() {
      return [
        { value: '', text: 'All Company Sizes' },
        ...this.size_group_filters.map((f) => ({
          value: f.id,
          text: `${f.name} (${f.count})`,
        })),
      ]
    },
    the_sector_filters() {
      return [
        { value: '', text: 'All Industries' },
        ...this.sector_filters.map((f) => ({
          value: f.id,
          text: `${f.name} (${f.count})`,
        })),
      ]
    },
    the_seniority_filters() {
      return [
        { value: '', text: 'All Seniority' },
        ...this.seniority_filters.map((f) => ({
          value: f.id,
          text: `${f.name} (${f.count})`,
        })),
      ]
    },
    the_recipient_role_filters() {
      return [
        { value: '', text: 'All Roles' },
        ...this.recipient_role_filters.map((f) => ({
          value: f.id,
          text: `${f.name} (${f.count})`,
        })),
      ]
    },
    the_survey_filters() {
      return [{ value: '', text: 'All Surveys' }, ...this.survey_filters]
    },
    the_volunteer_activity_filters() {
      return [
        { value: '', text: 'All Volunteers' },
        ...this.volunteer_activity_filters.map((a) => ({
          value: a['key'],
          text: `${a['key']} (${a['doc_count']})`,
        })),
      ]
    },
    the_tag_filters() {
      return [
        { value: '', text: 'All Tags' },
        ...this.tag_filters.map((f) => ({
          value: f.id,
          text: `${f.name} (${f.count})`,
        })),
      ]
    },
  },
}
