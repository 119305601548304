export default {
  methods: {
    extractHostnameIfUrl(source) {
      try {
        return new URL(source).hostname
      } catch {
        return source
      }
    },
  },
  computed: {
    sourceText() {
      if (this.contentAsset?.imported_at) {
        if (this.contentAsset.source) {
          return `Source: ${this.extractHostnameIfUrl(this.contentAsset.source)}`
        }

        return `Collected by ${this.account.name}`
      }

      if (this.responseCount) {
        return `${this.sourceClauseOne}, conducted by UserEvidence.`
      }

      return `Survey conducted by UserEvidence.`
    },
    brandedSourceText() {
      return `${this.sourceClauseOne}.`
    },
    // branded assets only want the first part of this sentence.  the basic assets want the whole thing.
    sourceClauseOne() {
      return `Survey of ${this.responseCount} ${this.account.name} users`
    },
    responseCount() {
      return this.contentAsset?.response_count
    },
  },
}
