<template>
  <b-button
    @click="$emit('click')"
    :variant="variant"
    :size="size"
    :class="`button_new d-flex align-items-center`"
  >
    <slot></slot>
  </b-button>
</template>

<script lang="ts">
// Basically a wrapper around the BootstrapVue button component
// with some custom styling (accepts a slot so you can pass in Icons that get automatically aligned)
export default {
  name: 'ButtonNew',
  props: {
    size: {
      type: String,
      default: 'md',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
}
</script>

<style lang="sass" scoped>
.button_new
  &.btn
    width: fit-content
    cursor: pointer
    font-size: 16px
    font-weight: 700
    border-radius: 8px
    gap: 8px
    padding: 8px 16px 8px 16px
    ::v-deep svg
      path
        fill: currentColor

    &.btn-primary
      color: white
      background: $uePurple
      border: 1px solid $uePurple

      &:hover
        color: $ueLighterPurple
        border: 1px solid darken($uePurple, 10%)
        background: darken($uePurple, 10%)

      ::v-deep svg
        path
          fill: currentColor

    &.btn-secondary
      color: $uePurple
      background: white
      border: 1px solid $uePurple

      &:hover
        color: $uePurple
        border: 1px solid $uePurple
        background: $ueLighterPurple
      ::v-deep svg
        path
          fill: $uePurple
</style>
