<template lang="pug">
  a(@click='copySnippet()' href='#')
      EmbedIcon.mr-1
      | Copy Embed Code
</template>

<script lang="ts">
import EmbedIcon from '@components/graphics/EmbedIcon'

export default {
  components: {
    EmbedIcon,
  },
  props: {
    snippet: {
      type: String,
      required: true,
    },
  },
  methods: {
    copySnippet() {
      navigator.clipboard.writeText(this.snippet)
      this.$toast('Embed Code copied to clipboard')
    },
  },
}
</script>
