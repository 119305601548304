export interface DownloadOptions {
  contentType: string
  filename: string
}

export function downloadBlob(data: BlobPart[], { contentType, filename }: DownloadOptions) {
  const blob = new Blob(data, {
    type: contentType,
  })

  const url = URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = filename
  anchor.style.display = 'none'

  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
  URL.revokeObjectURL(url)
}
