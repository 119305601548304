<template lang="pug">
.mobile_menu
  a.hamburger_icon(@click='showMobileMenu = !showMobileMenu' :class="{'mobile_menu_open': showMobileMenu}" role='button')
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class='bar_one' d="M8 9H24" stroke="hsl(200, 16%, 56%)" stroke-width="2" stroke-linecap="round"/>
    <path class='bar_two' d="M8 16H24" stroke="hsl(200, 16%, 56%)" stroke-width="2" stroke-linecap="round"/>
    <path class='bar_three' d="M8 23H24" stroke="hsl(200, 16%, 56%)" stroke-width="2" stroke-linecap="round"/>
    </svg>

  transition(name='fade')
    .navbar_mobile_menu(v-show='showMobileMenu')
      ul
        li
          a.text-link.navbar-link(href='/product' aria-label='Product' title='Product') Product
        li
          a.text-link.navbar-link(href='/user-research-library' aria-label='Research Library' title='Research Library') Research Library
        li
          a.text-link.navbar-link(href='/contact' aria-label='Contact Us' title='Contact Us') Contact Us
        li
          a.text-link.navbar-link(href='/blog' aria-label='Blog' title='Blog') Blog
        li
          a.text-link.navbar-link(href='/resource-library' aria-label='Resources' title='Resources') Resources
        li
          a.text-link.navbar-link(href='/users/sign_in' aria-label='Log In' title='Log In') Log In
</template>
<script lang="ts">
export default {
  name: 'MobileMenu',
  data() {
    return {
      showMobileMenu: false,
    }
  },
}
</script>
