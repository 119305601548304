import randomHash from './random-hash'
import Color from 'color'
import dayjs from 'dayjs'
import fetchSummary from './fetchSummary'

const defaultIntro = (recipient, account) => {
  const survey_date = (recipient.completed_at) ? `${dayjs(recipient.completed_at).format('MMMM')} ${dayjs(recipient.completed_at).format('YYYY')}` : ''
  return `This Customer Spotlight is a synopsis of how ${companyReference(recipient)} used ${account.name} to benefit their business. The feedback included was collected and verified in a ${survey_date} survey of ${account.name} customers conducted by UserEvidence, an independent research firm.`
}

const companyReference = (recipient) => {
  // if (!this.recipient_data) { return null }
  // const recipient = this.recipient_data.survey.recipients[0]
  if(recipient.named && recipient.best_company_name)
      return recipient.best_company_name
  else {
    const raw_size_group = recipient.best_size_group || recipient.size_group
    const size_string = (raw_size_group) ? raw_size_group.split('_').map(w => w[0].toUpperCase() + w.substring(1)).join(' ') : ''
    return `a ${size_string} ${recipient.best_industry_name} company`
  }
}

const makeKeyStatBlock = (response, question, hash) => {
  // value_answer is an integer for NPS
  let number = response.value_answer
    // for other multiple choice questions we have to calculate a number...
  if (!number) {
    const answer = question.answers.find( a => a.id === response.answer_id )

    if (answer.low_value && answer.high_value) {
      // here we invent a single integer by averaging the pre-set low and high value
      // attributes of the question's potential answers. This feels stinky and weird
      // (to Emerson) but management insists this is a essential functionality 🤷‍♂️
      number = (answer.low_value + answer.high_value) / 2
      number = Math.round(number)
    } else {
      // abort the mission if we don't have the high/low values
      // This is expected for answers like "I don't know" or "Other"
      return false
    }

  } 

  return {
    type: 'Key Stat',
    number: number,
    qualifier: question.qualifier,
    tagline: question.stat_tagline,
    show: true,
    key: hash
  }

}

const makeCustomerSpotlightFromData = (recipient, questions, account, auto_summaries) => {

  const key_stat_questions = questions.filter( q => q.stat_tagline && q.qualifier )

  const key_stat_blocks = []

  key_stat_questions.map( q => {

      const response = recipient.responses.find( r => r.question_id === q.id )

      // Some questions won't have answers so skip
      if (!response) { return }

      const new_block = makeKeyStatBlock(response, q, randomHash())
      // This will be false if the answer doesn't have high/low value. Ex: "I don't know"
      if (new_block) { key_stat_blocks.push(new_block) }
  })

  const testimonial_blocks = []
  const testimonials_questions = questions.filter( q => q.type === 'Testimonial')
  testimonials_questions.map( q => {
      const response = recipient.responses.find( r => r.question_id === q.id && r.text_answer )
      if (response) {
          testimonial_blocks.push(
              {
                  type: 'Testimonial',
                  quote: response.text_answer,
                  author_name: (recipient.named) ? `${recipient.first_name} ${recipient.last_name}` : null, 
                  author_title: recipient.title,
                  author_company: recipient.company_attribution,
                  show: true,
                  key: randomHash(),
                  original_question: q
              }
          )
      }
  })

  const scenario_question_blocks = []
  const outcome_question_blocks = []
  const misc_question_blocks = []
  questions.map(q => {

    const answers = recipient.responses.filter( r => r.question_id === q.id ).map( r => {
        return r.text_answer || r.value_answer?.toString() || q.answers.find(a => r.answer_id === a.id).the_answer
    })

      // Skip if question has no answer
      if (!answers?.length) {return}

      const block = {
          type: 'Question',
          prompt: q.the_question,
          answers: answers,
          show: false, // we update this conditionall below
          key: randomHash(),
          original_question: q
      }

      switch(q.category) {
          case 'scenario':
              block.show = true
              scenario_question_blocks.push(block)
              return
          case 'outcome':
              // NPS is an outcome but in most cases it's already a key stat, so hide
              block.show = (q.type === 'Nps') ? false : true
              outcome_question_blocks.push(block)
              return
          default:
              misc_question_blocks.push(block)
      }
  })

  let auto_summary_texts;
  
  const blocks = [
    {
        type: 'Text',
        title: 'Introduction',
        text: (auto_summaries) ? 'Generating auto-summary...' : defaultIntro(recipient, account),
        show: true,
        generate_auto_summary: (auto_summaries) ? 'introduction' : false,
        key: randomHash()
    },
  ];
  if (key_stat_blocks.length) {
      const plural = (key_stat_blocks.length > 1) ? 's' : ''
      blocks.push(
          {
              type: 'Text',
              title: `Key Stat${plural}`,
              text: null,
              show: true,
              key: randomHash()
          },
          ...key_stat_blocks
      )
  }
  if (testimonial_blocks.length) {
      blocks.push(...testimonial_blocks)
  }

  if(scenario_question_blocks.filter(b => b.show).length) {
      blocks.push(
          {
              type: 'Text',
              title: 'Scenario',
              text: (auto_summaries) ? 'Generating auto-summary...' : null,
              generate_auto_summary: (auto_summaries) ? 'scenario' : false,
              show: true,
              key: randomHash()
          },
          ...scenario_question_blocks
      )
  }

  if(outcome_question_blocks.filter(b => b.show).length) {
      blocks.push(
          {
              type: 'Text',
              title: 'Outcome',
              text:  (auto_summaries) ? 'Generating auto-summary...' : null,
              generate_auto_summary: (auto_summaries) ? 'outcome' : false,
              show: true,
              key: randomHash()
          },
          ...outcome_question_blocks
      )
  }

  if(account?.introduction) {
      blocks.push({
          type: 'Text',
          title: `About ${account.name}`,
          text: account.introduction,
          show: true,
          key: randomHash()
      })
  }

  if(misc_question_blocks.length) {
      blocks.push(
          ...misc_question_blocks
      )
  }

  // Native color picker only supports HEX value
  const default_accent_color = Color(account.brand_color_1 || account.brand_color_2).hex()

  return {
      title: null,
      survey_id: recipient.survey_id,
      recipient_id: recipient.id,
      identifier: 'TBD',
      recipient: recipient,
      show_customer_profile: true,
      accent_color: default_accent_color,
      blocks: blocks,
      classifier_values: []
  }
}

const colorToHex = (color) => Color(color).hex()

async function fetchAutoSummaries(recipient, scenario_question_blocks, outcome_question_blocks) {
    let introduction_response;
    let scenario_response;
    let outcome_response;

    try {
        const introduction_promise = fetchSummary('introduction', companyReference(recipient), [...scenario_question_blocks, ...outcome_question_blocks]);
        const scenario_promise = fetchSummary('scenario', companyReference(recipient), [...scenario_question_blocks]);
        const outcome_promise = fetchSummary('outcome', companyReference(recipient), [...outcome_question_blocks]);

        introduction_response = await introduction_promise;
        scenario_response = await scenario_promise;
        outcome_response = await outcome_promise;

    } catch (error) {
        alert('There was a problem generating the auto-summaries. Please try again or contact support for assistance.')
        console.error(error)
        return {introduction: null, scenario: null, outcome: null}
    }
    return {
        introduction: introduction_response.data,
        scenario: scenario_response.data,
        outcome: outcome_response.data
    }
}

export {
  makeCustomerSpotlightFromData,
  defaultIntro,
  companyReference,
  makeKeyStatBlock,
  colorToHex
}
