<template lang="html">
  <ButtonNew variant="secondary" @click="copySnippet()" href="#">
    <div v-html="codeIcon" />
    Copy Embed Code
  </ButtonNew>
</template>

<script lang="ts">
import ButtonNew from '@components/new_components/ButtonNew.vue'
import codeIconSvg from '@images/icons/code-icon.svg?raw'
export default {
  name: 'CopyEmbedCodeNew',
  components: {
    ButtonNew,
  },
  computed: {
    codeIcon() {
      return codeIconSvg
    },
  },
  props: {
    snippet: {
      type: String,
      required: true,
    },
  },
  methods: {
    copySnippet() {
      navigator.clipboard.writeText(this.snippet)
      this.$toast('Embed Code copied to clipboard')
    },
  },
}
</script>
