export default {
  computed: {
    // Decides which text to display in the header
    // it gives precedence to the title, then the question text
    headerText() {
      if (this.contentAsset.show_title) {
        return this.contentAsset.title
      } else if (this.contentAsset.show_question) {
        // Renderable testimonial uses the `question_text` field
        // which is the same as question.the_question.
        // But we keep it here to make sure it is safe
        if (this.contentAsset.type == 'RenderableTestimonial') {
          return this.contentAsset.question_text
        }
        return this.contentAsset.question?.the_question
      }
      // Render blank when both toggles are off
      return ''
    },
  },
}
