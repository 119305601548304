/*
 * This is used on the marketing research library page for the case when a user comes into the research library via a UTM link, clicks on a research study, and then clicks on the "Back to homepage" button.
 * We want to preserve the UTM params so that we can attribute the user's actions to the UTM link that they clicked on.
 */
const utmParams = {
  setup() {
    const backToHomepage = document.getElementById('back-to-homepage')
    if (!backToHomepage) return

    this.saveUtmParams()
    this.backToHomepageClickHandler = this.backToHomepageClickHandler.bind(this)
    backToHomepage.addEventListener('click', this.backToHomepageClickHandler)
  },
  saveUtmParams() {
    const searchParams = new URLSearchParams(window.location.search)
    let utmParams = {}

    if (searchParams.get('utm_source') === null) return

    utmParams = {
      utmSource: searchParams.get('utm_source'),
      utmMedium: searchParams.get('utm_medium'),
      utmCampaign: searchParams.get('utm_campaign'),
      utmTerm: searchParams.get('utm_term'),
      utmContent: searchParams.get('utm_content'),
    }
    if (Object.keys(utmParams).length === 0) return

    document.cookie = 'utm_source=' + encodeURIComponent(utmParams.utmSource)
    document.cookie = 'utm_medium=' + encodeURIComponent(utmParams.utmMedium)
    document.cookie = 'utm_campaign=' + encodeURIComponent(utmParams.utmCampaign)
    document.cookie = 'utm_term=' + encodeURIComponent(utmParams.utmTerm)
    document.cookie = 'utm_content=' + encodeURIComponent(utmParams.utmContent)
  },
  getCookie(name) {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  },
  getUtmParams() {
    return {
      utm_source: this.getCookie('utm_source'),
      utm_medium: this.getCookie('utm_medium'),
      utm_campaign: this.getCookie('utm_campaign'),
      utm_term: this.getCookie('utm_term'),
      utm_content: this.getCookie('utm_content'),
    }
  },
  getUtmParamsQueryString: (params) => {
    return Object.keys(params)
      .filter((key) => params[key])
      .map((key) => `${key}=${params[key]}`)
      .join('&')
  },
  backToHomepageClickHandler() {
    window.location.href =
      'https://www.userevidence.com?' + this.getUtmParamsQueryString(this.getUtmParams())
  },
}

export default utmParams
