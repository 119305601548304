<template lang="pug">
section.block.question-block
  p {{prompt}}
  ul
    li(v-for='answer in answers') {{answer}}

</template>
<script lang="ts">
export default {
  components: {},
  props: ['prompt', 'answers'],
  data() {
    return {}
  },
  // computed: {
  //   prompt() {},
  //   answers() {}
  // },
  // methods: {
  //   someMethod() {
  //   },
  // }
}
</script>
<style lang="sass">
.question-block
  ul
    li
      background: url('../graphics/radio_button.svg') no-repeat
      background-position: left .35em
      padding-left: 20px
      font-weight: 700
      list-style: none
      line-height: 1.5
</style>
