<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10V2ZM9.53033 4.53033C9.82322 4.23744 9.82322 3.76256 9.53033 3.46967C9.23744 3.17678 8.76256 3.17678 8.46967 3.46967L5 6.93934L3.53033 5.46967C3.23744 5.17678 2.76256 5.17678 2.46967 5.46967C2.17678 5.76256 2.17678 6.23744 2.46967 6.53033L4.46967 8.53033C4.76256 8.82322 5.23744 8.82322 5.53033 8.53033L9.53033 4.53033Z"
        :fill="fill"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      default: '#850AFF',
    },
  },
}
</script>
