<template lang="html">
  <ButtonNew variant="secondary" @click="copySnippet()" href="#">
    <ShareIcon class="mr-1" />
    Copy Share Link
  </ButtonNew>
</template>

<script lang="ts">
import ButtonNew from '@components/new_components/ButtonNew.vue'
import ShareIcon from '@app/graphics/ShareIcon'
import { generateShareLink } from '@lib/share_link'

export default {
  components: {
    ButtonNew,
    ShareIcon,
  },
  props: {
    ueId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ueviLink() {
      return generateShareLink(this.ueId)
    },
  },
  methods: {
    copySnippet() {
      navigator.clipboard.writeText(this.ueviLink)
      this.$emit('copied')
      this.$toast('Share link copied to clipboard')
    },
  },
}
</script>
