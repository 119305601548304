<template>
  <div class="survey_summary report_type_asset horizontal">
    <ReportHeader :account="account" :contentAsset="contentAsset" />
    <div class="content">
      <div class="content_container">
        <section>
          <h3>Introduction</h3>
          <p v-html="renderMarkdown(contentAsset.introduction)"></p>
        </section>
        <section>
          <h3>Common Themes</h3>
          <b-badge
            pill
            size="sm"
            variant="light"
            class="mr-2 mb-2 p-3"
            v-for="theme in contentAsset.keywords"
            :key="theme"
          >
            {{ theme }}
          </b-badge>
        </section>
        <section v-if="contentAsset.positive_summary">
          <h3>Positive Feedback</h3>
          <p v-html="renderMarkdown(contentAsset.positive_summary)"></p>
        </section>
        <section v-if="contentAsset.negative_summary">
          <h3>Negative Feedback</h3>
          <p v-html="renderMarkdown(contentAsset.negative_summary)"></p>
        </section>
        <section v-if="contentAsset.feedback_summary">
          <h3>Opportunities for Improvement</h3>
          <p v-html="renderMarkdown(contentAsset.feedback_summary)"></p>
        </section>
        <section v-if="contentAsset.nps_summary">
          <h3>NPS Score Breakdown</h3>
          <p v-html="renderMarkdown(contentAsset.nps_summary)"></p>
        </section>
        <section v-if="contentAsset.advocacy_summary">
          <h3>Advocacy Participation</h3>
          <p v-html="renderMarkdown(contentAsset.advocacy_summary)"></p>
        </section>
        <section v-if="contentAsset.mcm_summary">
          <h3>Multiple Choice Question Summary</h3>
          <p v-html="renderMarkdown(contentAsset.mcm_summary)"></p>
        </section>
        <section v-if="contentAsset.mco_summary">
          <h3>Single Select Question Summary</h3>
          <p v-html="renderMarkdown(contentAsset.mco_summary)"></p>
        </section>
        <section v-if="contentAsset.conclusion">
          <h3>Conclusion</h3>
          <p v-html="renderMarkdown(contentAsset.conclusion)"></p>
        </section>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ReportHeader from './basic_asset_parts/reports/ReportHeader.vue'
import MarkdownIt from 'markdown-it'
import { getBasicColor } from '@lib/colors'
export default {
  components: {
    ReportHeader,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    contentAsset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      markdownToHtmlService: new MarkdownIt(),
    }
  },
  computed: {
    primaryColor() {
      return getBasicColor(this.account, 'primary')
    },
  },
  methods: {
    renderMarkdown(section) {
      if (section) return this.markdownToHtmlService.render(section)
    },
  },
}
</script>
<style lang="sass" scoped>
section h3
  color: v-bind(primaryColor)
  font-weight: bold
  margin-bottom: 0.5em
.survey_summary .content section
  margin: 3em auto
</style>
