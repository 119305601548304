<template lang="pug">
.bar_guts(:class='orientation' v-if="stats")
  .bar_group(v-for='(stat, i) in nonZeroStats')
    .channel(:style='')
      .bar(:style='barStyle(stat.count)')
        .stat 
          | {{statPercent(stat.count)}}
          span %
    .answer {{ stat.freeform ? 'Other' : stat.the_answer }}
</template>

<script>
import { gradientColors } from '@lib/colors'

export default {
  props: {
    // This is needed by functions imported from colors.ts
    account: { type: Object, required: true },
    stats: {
      type: Array,
    },
    total: { type: Number, default: 0 },
  },
  computed: {
    nonZeroStats() {
      return this.stats.filter((s) => s.count > 0 && !s.exclude_from_asset)
    },
    shortChart() {
      return Math.max(...this.nonZeroStats.map((stat) => Number(this.statPercent(stat.count)))) < 50
    },
    orientation() {
      return Math.max(...this.nonZeroStats.map((s) => s.the_answer && s.the_answer.length), 0) > 10
        ? 'horizontal'
        : 'vertical'
    },
    maxPercent() {
      return Math.max(...this.nonZeroStats.map((stat) => Number(this.statPercent(stat.count))))
    },
    gradient1() {
      return this.gradientColors[0]
    },
    gradient2() {
      return this.gradientColors[1]
    },
    gradientColors,
  },
  methods: {
    statPercent(value) {
      return Math.round((value / this.total) * 100)
    },
    barStyle(value) {
      if (this.orientation == 'horizontal') {
        var width = this.statPercent(value)
        if (this.shortChart) width = width * 1.9
        return {
          width: width + '%',
          background: `linear-gradient(180deg, ${this.gradient1}, ${this.gradient2})`,
        }
      } else {
        var height = Number(this.statPercent(value))
        return {
          height: (height / this.maxPercent) * 100 + '%',
          background: `linear-gradient(180deg, ${this.gradient1}, ${this.gradient2})`,
        }
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.bar_chart
  .bar_guts
    height: 100%
    width: 100%
    margin: 0 auto
  .horizontal
    .bar_group
      &:not(:last-child)
        margin-bottom: 16px
    .bar
      border-radius: 0 8px 8px 0
      padding: 0
      height: 40px
      color: white
      display: flex
      align-items: flex-end
      min-width: 48px
    .answer
      margin-top: 4px
      width: 100%
    .stat
      left: 12px
  .vertical
    height: 350px
    display: flex
    justify-content: space-between
    align-items: flex-end
    .channel
      height: 100%
      display: flex
      flex-direction: column
      justify-content: flex-end
    .bar_group
      display: flex
      flex-direction: column
      justify-content: flex-end
      width: 100%
      height: 100%
      &:not(:last-child)
        margin-right: 2%
    .bar
      border-radius: 8px 8px 0px 0px
      padding: 0
      color: white
      display: flex
      justify-content: center
      align-items: flex-end
      height: 100%
      width: 100%
    .answer
      margin-top: 8px
      justify-content: center

  .vertical .answer, .horizontal .answer
    display: flex
    letter-spacing: -0.02em
    font-size: 12px
    line-height: 16px
    color: hsl(200, 8%, 8%)

  .stat
    font-size: 30px
    letter-spacing: -0.04em
    line-height: 1
    position: relative
    bottom: -4px
    text-shadow: 1px 0px 0 hsla(200, 8%, 8%, 0.5)
    span
      font-size: 14px
      letter-spacing: 0
      line-height: 1
      color: hsla(200, 100%, 100%, 0.8)
      position: relative
      left: 2px
  .bar3
    width: 80%
    .channel, .answer
      width: 90% !important

  .bar7 .stat, .bar8 .stat, .bar9 .stat
    bottom: -3px
    span
      font-size: 10px

  .bar7 .stat
    font-size: 20px
    span
      font-size: 12px

  .bar8 .stat
    font-size: 19px
    span
      font-size: 11px

  .bar9 .stat
    font-size: 18px

  .barx .stat
    bottom: -2px
    font-size: 16px
    span
      font-size: 9px

  .vertical .answer, .horizontal .answer, .stat span
    font-weight: 500

  .bar_guts, .bar_group, .stat
    font-weight: 800
</style>
