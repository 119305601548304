<template lang="html">
  <div
    class="branded_style_asset p-4"
    :class="{ 'brand-font-enabled': brandFont }"
    :style="bothBackgroundCssVars"
  >
    <div class="container brand-font">
      <div
        v-if="showLogo && this.logoSizeOption != 'small'"
        class="logo_header d-flex justify-content-center pt-4"
      >
        <span v-html="this.account.svg_logo" class="logo" aria-hidden></span>
      </div>

      <div class="d-flex px-3 pt-3 pb-4">
        <span
          class="headline"
          v-html="headline"
          :style="{ color: this.getBasicColor(this.account, this.statColorOption) }"
        ></span>
        <span class="sentence pl-3" v-html="sentence"></span>

        <span v-if="showLogo && this.logoSizeOption == 'small'" class="d-flex mr-0">
          <span v-html="this.account.svg_logo_mark" class="logo" aria-hidden></span>
        </span>
      </div>
    </div>

    <StatBrandedFooter :ueId="contentAsset.identifier" class="pt-3" :color="footerColor" />
  </div>
</template>
<script lang="ts">
import StatBrandedFooter from 'src/components/branded_asset_parts/stat/StatBrandedFooter.vue'
import BrandedAssetMixin from '@app/mixins/branded_asset.ts'
import { injectFontFamily } from '@lib/injectFont'
import AssetSourceMixin from '@app/mixins/asset_source'

export default {
  name: 'StatBranded',
  components: { StatBrandedFooter },
  mixins: [AssetSourceMixin, BrandedAssetMixin],
  props: {
    contentAsset: { type: Object, required: true },
    statColorOption: { type: String, default: 'primary' },
  },
  beforeMount() {
    if (this.brandFont) {
      injectFontFamily(this.brandFont)
    }
  },
  data() {
    return {
      account: this.contentAsset.account,
    }
  },
  computed: {
    headline() {
      return this.contentAsset.headline || this.contentAsset.rendered_headline
    },
    sentence() {
      return this.contentAsset.sentence || this.contentAsset.rendered_sentence
    },
    showLogo() {
      return this.logoSizeOption !== 'none'
    },
    brandFont() {
      return this.account?.brand_font
    },
  },
}
</script>
<style lang="sass" scoped>
.headline
  font-size: 45px
  font-weight: $font-weight-bold
  line-height: 60px
  word-wrap: break-word

.sentence
  font-size: 16px
  font-weight: 400
  line-height: 25px
  padding-left: 0.5rem
</style>
