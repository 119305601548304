export interface InjectFontOptionsInterface {
  fontFamilyName: string
  fileUrl: string
  fontWeight: string | number
  fontStyle?: string
  format?: string // I don't think this is necessary for our immediate use-case but it's here for future-proofing
}

export interface FontFaceInterface {
  style: string
  weight: string
  url: string
  format?: string
}

export interface FontFamilyInterface {
  name: string
  font_faces: FontFaceInterface[]
}

function assembleFontUrl(fontUrl: string, format?: string) {
  return format ? `url("${fontUrl}") format("${format}")` : `url("${fontUrl}")`
}

function loadFontFace(fontFace: FontFace) {
  return fontFace.load().then((loadedFontFace) => {
    document.fonts.add(loadedFontFace)
  })
}

function makeFontFace(options: InjectFontOptionsInterface) {
  const { fontFamilyName, fileUrl, fontWeight, fontStyle, format } = options
  return new FontFace(fontFamilyName, assembleFontUrl(fileUrl, format), {
    weight: fontWeight.toString(),
    style: fontStyle || 'normal',
  })
}

function makeInjectFontOptions(name: string, fontFaces: FontFaceInterface[]) {
  return fontFaces.map((fontFace) => ({
    fontFamilyName: name,
    fileUrl: fontFace.url,
    fontWeight: fontFace.weight,
    fontStyle: fontFace.style,
    format: fontFace.format,
  }))
}

export const defaultFontFamilyName = 'BrandFont'

export function injectFontFace(options: InjectFontOptionsInterface) {
  const fontFace = makeFontFace(options)
  loadFontFace(fontFace).catch((error) => {
    // This is expected to fail sometimes because saving the font file happens
    // asyc and the browser may try to load the font before it's ready.
    console.error('Failed to load font face', error)
  })
}

export function injectFontFamily(
  fontFamily: FontFamilyInterface,
  familyNameOverride: string | null,
) {
  if (!fontFamily?.font_faces?.length) {
    return
  }
  const name = familyNameOverride || defaultFontFamilyName
  makeInjectFontOptions(name, fontFamily.font_faces).forEach((options) => injectFontFace(options))
}
