import { render, staticRenderFns } from "./MatrixxChartBasic.vue?vue&type=template&id=b6527b22&scoped=true&lang=html"
import script from "./MatrixxChartBasic.vue?vue&type=script&lang=ts"
export * from "./MatrixxChartBasic.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6527b22",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/musashi/musashi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6527b22')) {
      api.createRecord('b6527b22', component.options)
    } else {
      api.reload('b6527b22', component.options)
    }
    module.hot.accept("./MatrixxChartBasic.vue?vue&type=template&id=b6527b22&scoped=true&lang=html", function () {
      api.rerender('b6527b22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/MatrixxChartBasic.vue"
export default component.exports