<template lang="html">
  <div class="branded-footer" :style="{ color: color }">
    <div class="wrapper d-flex justify-content-center">
      <span class="verified logo mr-2" v-html="svgUeLogo"></span> UE verified |&nbsp;
      <a class="ueid-link" :style="{ color: color }" :href="shareLink">uevi.co/{{ ueId }}</a>
    </div>
    <div v-if="surveySummary" class="survey-summary text-center pb-3">{{ surveySummary }}</div>
  </div>
</template>
<script lang="ts">
import ueLogoSvg from '@images/ue_logo_mark.svg?raw'
import { generateShareLink } from '@lib/share_link'

export default {
  props: {
    ueId: { type: String, required: true },
    color: { type: String, required: true },
    surveySummary: { type: String, required: false },
  },
  components: {},
  computed: {
    svgUeLogo() {
      return ueLogoSvg
    },
    shareLink() {
      return generateShareLink(this.ueId)
    },
  },
}
</script>
<style lang="sass" scoped>
.survey-summary
  font-size: 0.6666rem
  font-weight: 400
</style>
