<template lang="pug">
div(v-if="theme")
  .testimonial_custom(:style="style_vars" :class="shape" v-for="(the_page, index) in the_pages" :key="index")
    link(rel="stylesheet" :href="fontHref")

    .top-image(v-if="logo_src")
      img(:src="logo_src")

    .quote(v-html="`${beforeQuote(index)}${the_page}${afterQuote(index)}`")

    .middle-image(v-if="theme.middle_image_data_url")
      img(:src="theme.middle_image_data_url")

    .attribution
      .attribution__left
        .avatar-wrap
          AvatarIcon
      .attribution__right
        .person {{renderableTestimonial.attribution_line_1}}
        .title {{renderableTestimonial.attribution_line_2}}
        .company {{renderableTestimonial.attribution_line_3}}

    .bottom-image(v-if="theme.bottom_image_data_url")
      img(:src="theme.bottom_image_data_url")

    .ue(:class="theme.footer_style")
      span.text Powered by 
      span.logo
        UELogo(:fill="(theme.footer_style == 'light') ? '#FFF' : null")

</template>
<script lang="ts">
import UELogo from '../../src/app/graphics/UELogo.vue'
import AvatarIcon from './graphics/AvatarIcon.vue'
import paginate from '../lib/paginate'

export default {
  name: 'TestimonialCustom',
  components: { UELogo, AvatarIcon },
  props: ['renderableTestimonial', 'theme', 'shape', 'page', 'show_all_pages'],
  data() {
    return {
      largest_possible_font_size: 36,
      smallest_possible_font_size: 24,
    }
  },
  computed: {
    the_shape() {
      return this.shape || 'flex' // default to flex
    },
    the_page_num() {
      return this.page || 1 // default to first page
    },
    // This is the _visible_ content, even for a single page. When showing a single
    // page this will be an array with the current page (only). When show_all_pages == true
    // this will have all pages.
    the_pages() {
      const all_pages = this.all_the_pages
      return this.show_all_pages ? all_pages : [all_pages[this.the_page_num - 1]]
    },
    all_the_pages() {
      // skip pagination when shape is flex
      if (this.the_shape === 'flex') {
        return [this.renderableTestimonial.testimonial_text]
      }
      const wide_max_chars = Math.ceil(
        170 * (this.largest_possible_font_size / this.theme.quote_size_px),
      )
      const square_max_chars = Math.ceil(
        380 * (this.largest_possible_font_size / this.theme.quote_size_px),
      )
      const max_page_chars = this.shape === 'wide' ? wide_max_chars : square_max_chars
      const text = this.renderableTestimonial.testimonial_text
      const max_page_chars_adjusted_for_top_image = this.adjustCharLimitForImage(max_page_chars)
      return text && max_page_chars_adjusted_for_top_image
        ? paginate(text, max_page_chars_adjusted_for_top_image)
        : ['']
    },
    total_page_count() {
      return this.all_the_pages.length
    },
    fontHref() {
      const quoteFontName = this.theme?.quote_font ? this.theme.quote_font.replace(/ /g, '+') : null
      const authorFontName = this.theme?.author_font
        ? this.theme.author_font.replace(/ /g, '+')
        : null
      const fonts = [quoteFontName, authorFontName].filter((a) => a)
      return `https://fonts.googleapis.com/css?family=${fonts.join('|')}`
    },
    author_align_self() {
      if (this.theme.author_alignment === 'center') {
        return 'center'
      }
      if (this.theme.author_alignment === 'right') {
        return 'flex-end'
      }
      return 'flex-start'
    },
    style_vars() {
      // initially the theme prop might be `null`. This removes the race conidtion
      if (!this.theme) {
        return {}
      }

      // have to add the url() wrapper in order for the custom property to work
      const bgImageUrl = this.theme.background_image_data_url
        ? `url(${this.theme.background_image_data_url})`
        : null

      return {
        // background
        '--background_image_data_url': bgImageUrl,
        '--background_size': this.theme.background_size,
        '--background_repeat': this.theme.background_repeat ? 'repeat' : 'no-repeat',
        '--background_color': this.theme.background_color,

        // quote
        '--quote_font': this.theme.quote_font,
        '--quote_size_px': `${this.theme.quote_size_px}px`,
        '--quote_alignment': this.theme.quote_alignment,
        '--quote_color': this.theme.quote_color,

        // top_image
        '--top_image_alignment': this.theme.top_image_alignment,
        '--top_image_height_px': `${this.theme.top_image_height_px}px`,

        // middle_image
        '--middle_image_alignment': this.theme.middle_image_alignment,
        '--middle_image_height_px': `${this.theme.middle_image_height_px}px`,

        // bottom
        '--bottom_image_alignment': this.theme.bottom_image_alignment,
        '--bottom_image_height_px': `${this.theme.bottom_image_height_px}px`,

        // author
        '--author_font': this.theme.author_font,
        '--author_size_px': `${this.theme.author_size_px}px`,
        '--author_alignment': this.author_align_self,
        '--author_color': this.theme.author_color,

        // layout
        '--layout_justification': this.theme.layout_justification,
        '--layout_padding_top_px': `${this.theme.layout_padding_top_px || 0}px`,
        '--layout_padding_right_px': `${this.theme.layout_padding_right_px || 0}px`,
        '--layout_padding_bottom_px': `${this.theme.layout_padding_bottom_px || 0}px`,
        '--layout_padding_left_px': `${this.theme.layout_padding_left_px || 0}px`,
      }
    },
    logo_src() {
      return this.theme?.top_image_data_url
    },
  },
  methods: {
    pageIndicator(page) {
      const visible_page_number = this.page ? this.the_page_num : page + 1
      return `${visible_page_number} / ${this.total_page_count}`
    },
    lastPage(i) {
      return i == this.total_page_count - 1
    },
    showEllipsis(index) {
      const index_or_page_prop = this.page ? this.page : index + 1
      return this.total_page_count > 1 && index_or_page_prop < this.total_page_count
    },
    beforeQuote(index) {
      if (index === 0 && this.theme?.quote_add_marks) {
        return '&ldquo;'
      }
      if (index > 0) {
        return '&hellip;'
      }
      return ''
    },
    afterQuote(index) {
      if (this.showEllipsis(index)) {
        return '&hellip;'
      }
      if (index === this.total_page_count - 1 && this.theme?.quote_add_marks) {
        return '&rdquo;'
      }
      return ''
    },
    adjustCharLimitForImage(max_page_chars) {
      // if no top image, we're done
      if (!this.theme?.top_image_data_url) {
        return max_page_chars
      }

      // @36px roughly 34 chars fit on a line
      // @24px roughly 53 chars fit on a line
      const largest_font_chars_per_line = 34
      // we guess the number of chars per line based on the font size of the quote
      const estimated_chars_per_line = Math.ceil(
        (this.largest_possible_font_size / this.theme.quote_size_px) * largest_font_chars_per_line,
      )

      const line_height_ratio = 1.1
      const line_height_px = this.theme.quote_size_px * line_height_ratio
      const image_height = this.theme.top_image_height_px
      const image_height_in_lines = Math.ceil(image_height / line_height_px)

      const character_deduction = Math.ceil(image_height_in_lines * estimated_chars_per_line)
      // never return a negative number
      const adjusted_limit =
        character_deduction > max_page_chars ? 0 : max_page_chars - character_deduction
      return adjusted_limit
    },
  },
}
</script>
<style lang="sass">
.testimonial_custom

  --footer_height: 2em

  position: relative
  background-image: var(--background_image_data_url)
  background-size: var(--background_size)
  background-repeat: var(--background_repeat)
  background-color: white // default fallback
  background-color: var(--background_color)
  width: 624px
  height: 100% // flex height by default

  display: flex
  flex-direction: column
  justify-content: var(--layout_justification)
  padding-top: var(--layout_padding_top_px)
  padding-right: var(--layout_padding_right_px)
  padding-bottom: calc(var(--footer_height) + 1.5em) // don't overlap the "Powered by"
  padding-left: var(--layout_padding_left_px)

  &.square
    // aspec-ratio is not supported by older puppeteer
    // aspect-ratio: 1 / 1
    // width: 624px
    height: 624px
  &.wide
    // aspect-ratio: 16 / 9 (+1 px)
    // width: 624px
    height: 352px

  .top-image
    text-align: var(--top_image_alignment)
    height: var(--top_image_height_px)

  .middle-image
    text-align: var(--middle_image_alignment)
    height: var(--middle_image_height_px)

  .bottom-image
    text-align: var(--bottom_image_alignment)
    height: var(--bottom_image_height_px)

  .top-image, .middle-image, .bottom-image
    margin-bottom: 1em

  .top-image img,
  .middle-image img,
  .bottom-image img
    height: 100%
    width: auto

  .quote
    color: var(--quote_color)
    font-family: var(--quote_font)
    font-size: var(--quote_size_px)
    text-align: var(--quote_alignment)
    flex-shrink: 1
    // overflow: hidden
    line-height: 1.1

    div
      display: inline

  .attribution
    color: var(--author_color)
    font-family: var(--author_font)
    font-size: var(--author_size_px)
    align-self: var(--author_alignment)
    line-height: 1.3
    margin-top: 1em
    display: flex
    align-items: center

    .avatar-wrap
      height: 48px
      width: 48px
      border: 1px solid currentColor
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      margin-right: 1em

      svg
        width: 24px
        height: 24px

        path
          fill: currentColor

    .attribution__right
      *:first-child
        font-weight: bold!important
      *:not(:first-child)
        font-size: 80%

  .ue
    font-size: .8em
    position: absolute
    bottom: calc(var(--footer_height) / 2)
    height: var(--footer_height)
    padding-top: .25em
    padding-bottom: .25em
    // left: var(--layout_padding_px)
    left: var(--layout_padding_left_px)
    .logo svg
      display: inline-block
      height: 1.8em
      margin-top: -.1em
    &.light
      color: #FFF
      .ue-logo-wordmark
        fill: currentColor
</style>
