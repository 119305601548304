<template>
  <div class="branded_testimonial" :class="{ 'brand-font-enabled': brandFont }" :style="cssVars">
    <div class="testimonial_container">
      <div class="guts brand-font">
        <div class="testimonial_header">
          <div class="quote" v-html="svgQuoteMark"></div>
          <div class="logo">
            <div v-html="account.svg_logo"></div>
          </div>
        </div>
        <div class="testimonial_body">
          <h1 v-if="renderableTestimonial.show_question">
            {{ renderableTestimonial.question_text }}
          </h1>
          <h1 v-else-if="renderableTestimonial.show_title">
            {{ renderableTestimonial.title }}
          </h1>
          <div class="testimonial_text" v-html="renderableTestimonial.testimonial_text"></div>
          <div class="attribution">
            <h2>{{ renderableTestimonial.attribution_line_1 }}</h2>
            <h5>{{ attributionLine }}</h5>
          </div>
          <div class="avatar" v-if="renderableTestimonial.avatar_url">
            <img :src="renderableTestimonial.avatar_url" :alt="renderableTestimonial.author_name" />
          </div>
        </div>
      </div>
      <div class="testimonial_footer">
        <div class="verification">
          <div class="verified"><UELogo /> UE verified</div>
          <a :href="shortLink" target="_blank">uevi.co/{{ renderableTestimonial.identifier }}</a>
        </div>
        <div class="nps" v-if="renderableTestimonial.show_nps">
          <h2>{{ renderableTestimonial.nps_score }}</h2>
          <h3>NPS</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import UELogo from 'src/components/graphics/Logo.vue'
import QuoteSvg from '@images/icons/rounded-quotation-mark.svg?raw'
import BrandedAssetMixin from '@app/mixins/branded_asset.ts'
import { injectFontFamily } from '@lib/injectFont'
import { generateShareLink } from '@lib/share_link'

export default {
  mixins: [BrandedAssetMixin],
  components: {
    UELogo,
  },
  props: {
    renderableTestimonial: { type: Object, required: true },
    account: { type: Object, required: true },
  },
  beforeMount() {
    if (this.brandFont) {
      injectFontFamily(this.brandFont)
    }
  },
  computed: {
    brandFont() {
      return this.account?.brand_font
    },
    svgQuoteMark() {
      return QuoteSvg
    },
    shortLink(): string {
      return generateShareLink(this.renderableTestimonial.identifier)
    },
    attributionLine() {
      return [
        this.renderableTestimonial.attribution_line_2,
        this.renderableTestimonial.attribution_line_3,
      ]
        .filter(Boolean)
        .join(', ')
    },
    logoImg() {
      return this.account?.logo_url
    },
    cssVars() {
      return Object.assign(this.bothBackgroundCssVars, {
        '--footer-color': this.footerColor,
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.branded_testimonial
  background: var(--background)
  padding: 0 32px 40px 32px
  border-radius: 20px
  width: 624px
  .guts
    position: relative
    background: white
    padding: 24px 32px 40px 32px
    margin-bottom: 20px
    border-radius: 0 0 32px 32px
    h1
      font-weight: 700
      font-size: 21px
      line-height: 31px
    div
      font-size: 18px
      line-height: 27px
    .testimonial_header
      display: flex
      margin-bottom: 20px
      justify-content: space-between
    .testimonial_text
      margin-bottom: 50px
    .attribution
      h2
        font-weight: 700
        font-optical-sizing: auto
        font-size: 24px
        margin-bottom: 0px
      h5
        font-style: italic
        border-bottom: none
        font-size: 16px
        margin: 0px
        &:after
          display: none
    .quote
      ::v-deep svg
        path
          fill: var(--brand-color-1)
    .avatar
      width: 123px
      height: 123px
      position: absolute
      bottom: 5px
      right: -10px
      img
        width: 100%
        border-radius: 50%
        margin-top: 20px
        border: 3px solid white
        box-shadow: 0px 2px 4px 0px #00000040

  .testimonial_footer
    color: var(--footer-color)
    display: flex
    justify-content: space-between
    a
      color: var(--footer-color)
      font-size: 18px
      text-decoration: none
    .verified
      display: flex
      align-items: center
      font-weight: 700
      font-size: 18px
      line-height: 27px
      ::v-deep svg
        width: 20px
        margin-right: 10px
        path
          fill: var(--footer-color)
    .nps
      display: flex
      align-items: flex-end
      h2
        font-weight: 700
        font-size: 49px
        line-height: 49px
        margin-right: 10px
      h3
        font-weight: 700
        font-size: 27px
</style>
