<template>
  <div class="loader_container" v-if="loading">
    <div class="loader">
      <lottie :options="options" data-chromatic="ignore"></lottie>
    </div>
  </div>
</template>
<script lang="ts">
import { mapState } from 'pinia'
import Lottie from 'vue-lottie/src/lottie.vue'
import * as loading from '../graphics/loading.json'
import { useLoadingStore } from '@app/stores/LoadingStore'

export default {
  components: { Lottie },
  data() {
    return {
      options: {
        animationData: loading.default,
        animationSpeed: 1,
      },
    }
  },
  computed: {
    ...mapState(useLoadingStore, ['loading']),
    ...mapState(useLoadingStore, ['loaderQueue']),
  },
}
</script>
<style lang="sass" scoped>
.loader_container
  position: absolute
  display: flex
  align-content: center
  justify-content: center
  width: 100vw
  height: 100vh
  background: rgba(255, 255, 255, 0.8)
  top: 0px
  left: 0px
  z-index: 10000000
  .loader
    max-width: 64px
    ::v-deep path
      stroke: $uePurple
</style>
