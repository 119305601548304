// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/home/ue-email-signup-background-shape.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./graphics/radio_button.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "header[data-v-c1ea363c] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), var(--header-gradient);\n}\n.testimonial[data-v-c1ea363c] {\n  margin-bottom: 96px;\n  overflow: visible !important;\n}\n.bar_guts[data-v-c1ea363c] .bar_group .channel {\n  border-radius: 0 8px 8px 0;\n  background: #F2F6F7 !important;\n}\n.bar_guts[data-v-c1ea363c].legend {\n  display: none !important;\n}\n.chart_preview .vertical[data-v-c1ea363c] {\n  height: 387px;\n}\n.chart_preview h4[data-v-c1ea363c] {\n  margin-bottom: 20px;\n}\n.chart_preview .citation[data-v-c1ea363c] {\n  margin-top: 20px;\n  font-size: 12px;\n}\nul li[data-v-c1ea363c] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-position: left center;\n  padding-left: 20px;\n  font-weight: 700;\n  list-style: none;\n  line-height: 1.5;\n}", ""]);
// Exports
module.exports = exports;
