<template lang="pug">
section.block.testimonial-block
  .quote
    QuoteIcon2024.quote-icon.mb-2
    .quote__text {{quote}}
  .attribution
    .avatar
      AvatarIcon
    .author
      h3 {{author_name}}
      h4 {{author_title}}
      h4 {{author_company}}
</template>
<script lang="ts">
import QuoteIcon2024 from '../graphics/QuoteIcon2024.vue'
import AvatarIcon from '../graphics/AvatarIcon.vue'

export default {
  components: { QuoteIcon2024, AvatarIcon },
  props: ['quote', 'author_name', 'author_title', 'author_company'],
  data() {
    return {}
  },
}
</script>
<style lang="sass" scoped>
.testimonial-block
  ::v-deep .quote-icon
    height: 1.75em
    width: 1.75em

    path
      fill: var(--accent-color)

  .quote__text
    font-size: 1.25em

  .attribution
    display: flex
    margin-top: .5em
    align-items: center
    .avatar svg
      // outline: 2px solid var(--accent-color)
      // old Chrome doens't support border-radius for outline
      box-shadow: 0 0 0 2px var(--accent-color)

      border-radius: 50%
      margin-right: 1em
</style>
