<template lang="html">
  <div class="file-asset d-flex flex-column p-4" :class="{ as_list: renderAsList }">
    <div class="header d-flex flex-row">
      <b-icon class="header-icon" icon="file-richtext"></b-icon>
      <span>PDF</span>
    </div>
    <div v-if="fileAsset.title" class="title mt-2">{{ fileAsset.title }}</div>
    <div v-if="fileAsset.description" class="description mt-1">{{ fileAsset.description }}</div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'FileAsset',
  props: {
    fileAsset: { type: Object, required: true },
    // Toggle to check if the FileAsset should be rendered in a "List" context
    renderAsList: { type: Boolean, required: false, default: false },
  },
}
</script>
<style lang="sass" scoped>

.header-icon
  font-size: 1.2em

.file-asset
  width: 100%
  word-break: break-word
  border: 1px solid hsl(200, 24%, 92%)
  border-radius: 1.5em
  background: white

  .header
    gap: 0.5em
    font-weight: $font-weight-bold
    color: $uePurple
    span
      font-size: 0.8em

.title
  color: rgba(33, 37, 41, 1)
  font-weight: 700
  font-size: 1.3em

.description
  color: rgba(108, 116, 125, 1)
  font-weight: 400
  font-size: 0.875em

.as_list
  &:hover
    border: 1px solid $uePurple
  .title
    font-size: 1em
  .description
    font-size: 0.8em

.identifier-wrapper
  border-top: 1px solid rgba(206, 212, 218, 1)
  margin-top: 1em
  span
    padding-top: 1em
    font-weight: 700
    font-size: 0.875em
    margin-left: auto
    margin-right: 0
    color: rgba(0, 123, 255, 1)
</style>
