// Utilities for generating share links
// NOTE: These were introduced relatively late and not used universally,
// at least right now there are still many places where uevi.co links are constructed
// on the fly using outdated logic.

// @param {String} assetIdentifier - An asset.identifier value
export function generateShareLink(assetIdentifier: string) {
  return getShareLinkPrefix() + assetIdentifier
}

function getShareLinkPrefix() {
  if (inProductionApp()) {
    return 'https://uevi.co/'
  } else {
    return window.location.protocol + '//' + window.location.host + '/app/uevico/'
  }
}

// Determine if we are "in production" for the purposes of generating an asset share link.
// In this case "in production" only means the actual production environment, so for instance
// staging is not considered "in production".
function inProductionApp() {
  // It's a bit of a kludge, but currently we don't have another reliable way to determine
  // current environment in javascript land.
  return window.location.host.includes('app.userevidence.com')
}
