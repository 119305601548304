import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('LoadingStore', {
  state: () => {
    return {
      loaderQueue: [],
    }
  },
  getters: {
    loading(state) {
      return state.loaderQueue.length > 0
    },
  },
  // we push every request that comes through this axios into a queue on the store
  // then we remove it when the response returns
  // we are no longer loading when that queue is empty
  actions: {
    trackRequest(config, timeout = 10000) {
      this.loaderQueue.push(configToTrackableUrl(config))
      // to prevent an endless loading state, we remove the request from the queue after 3 seconds
      setTimeout(
        (state) => {
          state.untrackRequest(config)
        },
        timeout,
        this,
      )
    },
    untrackRequest(config) {
      const index = this.loaderQueue.indexOf(configToTrackableUrl(config))
      if (index > -1) this.loaderQueue.splice(index, 1)
    },
  },
})

// we want to track the full request including the params.
// the config object returned by the axios request does not give
// a clear picture of this, so we have to assemble the full
// thing from different parts of it
function configToTrackableUrl(config) {
  let params = ''

  if (config.params) params = new URLSearchParams(config.params).toString()

  return config.url + '?' + params
}
