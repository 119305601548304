<template lang="html">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 7C2.895 7 2 6.105 2 5C2 3.895 2.895 3 4 3C5.105 3 6 3.895 6 5C6 6.105 5.105 7 4 7Z"
      :stroke="color2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 21H3V17H1V12C1 10.895 1.895 10 3 10H4"
      :stroke="color2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 7C21.105 7 22 6.105 22 5C22 3.895 21.105 3 20 3C18.895 3 18 3.895 18 5C18 6.105 18.895 7 20 7Z"
      :stroke="color2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 21H21V17H23V12C23 10.895 22.105 10 21 10H20"
      :stroke="color2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 7C10.343 7 9 5.657 9 4C9 2.343 10.343 1 12 1C13.657 1 15 2.343 15 4C15 5.657 13.657 7 12 7Z"
      :stroke="color1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 23H9V17H7V12C7 10.895 7.895 10 9 10H15C16.105 10 17 10.895 17 12V18H15V23Z"
      :stroke="color1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: ['brand_color_1', 'brand_color_2'],
  computed: {
    color1() {
      return this.brand_color_1 || '#850AFF'
    },
    color2() {
      return this.brand_color_2 || '#850AFF50'
    },
  },
}
</script>
<style lang="sass" scoped></style>
