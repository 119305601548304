/*
Set up koala tracking.

This function should be called to set the logged in user (or recipient) and account.

@param [Hash<account>] an object representing the account, with a name attribute
@param [Hash<user>] an object representing the user, with name and email attributes
*/
export const setKoala = (account: hash, user: hash) => {
  if (typeof window.ko === 'undefined') return

  window.ko.identify(user.email, {
    name: user.first_name + ' ' + user.last_name,
    account_name: account.name,
  })
}

/*
Track a user event in Koala.

@param [String<event>] a string representing the event to track
@param [Hash<properties>] a hash of properties to track with the event
@return [undefined]
*/
export const koalaTrack = (event: string, properties: hash) => {
  if (typeof window.ko === 'undefined') return

  window.ko.track(event, properties)
}
