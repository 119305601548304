<template lang="pug">
.content_asset.microsite
  .header
    h5
      .mr3
        <svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="grid1x2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-grid1x2 mx-auto b-icon bi"><g data-v-41be6633=""><path d="M6 1H1v14h5V1zm9 0h-5v5h5V1zm0 9v5h-5v-5h5zM0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zm1 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z"></path></g></svg>
      | Microsite
  .mb5
    h2 {{asset_list.title}}
  .footer
    b-badge.mr3(pill variant='primary') {{asset_list.item_count}}
    span Assets
</template>

<script lang="ts">
export default {
  props: ['asset_list'],
  computed: {},
}
</script>

<style lang="sass" scoped>
.header
  margin-bottom: 15px
  h5
    margin-bottom: 0px
.mr3
  display: inline-block
  margin-right: 5px
.mb5
  margin-bottom: 20px
  h2
    font-size: 20px
    margin: 0px
// this is copied from bootstrap, we have it here so we dont have to include
// the full bootstrap css on the website
.badge
  display: inline-block
  background-color: $uePurple
  font-size: 75%
  font-weight: 700
  line-height: 1
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  padding: 0.25em 0.6em
  border-radius: 10rem
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
.footer
  color: rgb(19, 21,22)
  display: flex
  align-items: center
  span
    font-size: 14px
</style>
