import appsignal from './appsignal'

// This function returns a string ready to be rendered in the view
// it expects a JSON object coming from the backend: render_model_errors function
// see more: https://github.com/userevidence/musashi/blob/master/app/helpers/application_helper.rb#L66
export function renderModelErrors(errorObject): string {
  let msg = ''
  if (errorObject) {
    for (const key in errorObject) {
      msg += `${errorObject[key].join(', ')}`
    }
  }
  if (msg === '') {
    msg = 'An error occurred'
  }
  return msg
}

// Reports an error to AppSignal
export function reportError(e: Error, action: string, namespace: string): void {
  return appsignal.sendError(e, (span) => {
    span.setAction(action)
    span.setNamespace(namespace)
  })
}
