<template lang="html">
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M8 1C8 0.447715 7.55228 0 7 0H5C4.44772 0 4 0.447715 4 1V4H2C1.44772 4 1 4.44772 1 5V12H11V8C11 7.44772 10.5523 7 10 7H8V1Z"
        :fill="fill"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      default: '#850AFF',
    },
  },
}
</script>
