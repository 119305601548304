<template lang="pug">
  .hubspot_form
</template>

<script>
export default {
  name: 'CommentForm',
  props: ['hubspot_id'],
  mounted() {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '19880008',
      formId: 'a89a06c1-692d-4d27-9493-d62140c67ebb',
      target: '#comment_form',
      pageId: this.hubspot_id,
    })
  },
}
</script>
