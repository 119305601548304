<template lang="pug">
  section.block.text-block(:class="{'text-block--header': !text}")
    h1.text-block__title(v-if="title") {{title}}
    p.text-block__text(v-if="text && !generate_auto_summary") {{text}}
    Loader(v-if="generate_auto_summary")
</template>
<script lang="ts">
import Loader from '../../app/components/Loader.vue'

export default {
  components: { Loader },
  props: ['title', 'text', 'generate_auto_summary'],
  data() {
    return {}
  },
  // computed: {
  //   some_cumputed() {}
  // },
}
</script>
<style lang="sass">
.text-block

.text-block__title
  line-height: 1
  margin: 0 0 1rem
  color: hsl(200deg, 16%, 45%)
  // font-size: .725rem
  font-size: 1rem
  font-weight: 400
  text-transform: uppercase
  letter-spacing: 0.16em
.text-block__text
</style>
