<template lang="pug">
  .video_button
    button.button.button-secondary(title: 'Watch Product Video' @click='$modal.show("video_modal")')
      span.button-icon
        svg(width='16' height='16' viewbox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg')
          title Play Button Icon
          path.hover-fill(d='M6 2L15 8L6 14V2Z' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
      | Watch The Video
</template>
<script>
export default {
  name: 'VideoButton',
}
</script>
