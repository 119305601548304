<template lang="pug">
  a(@click='copySnippet()' href='#')
      ShareIcon.mr-1
      | Copy Share Link
</template>

<script lang="ts">
import ShareIcon from '@app/graphics/ShareIcon'
import { generateShareLink } from '@lib/share_link'

export default {
  components: {
    ShareIcon,
  },
  props: {
    ueId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ueviLink() {
      return generateShareLink(this.ueId)
    },
  },
  methods: {
    copySnippet() {
      navigator.clipboard.writeText(this.ueviLink)
      this.$emit('copied')
      this.$toast('Share link copied to clipboard')
    },
  },
}
</script>
