import { defineStore } from 'pinia'
import { fetchApp } from '@app/api'
import { setPendo } from '@lib/pendo'
import { setKoala } from '@lib/koala'
import { useUserStore } from '@app/stores/UserStore'

export const useAccountStore = defineStore('AccountStore', {
  state: () => {
    return {
      account: null,
    }
  },
  actions: {
    getApp() {
      return fetchApp().then((response) => {
        // user is the currently logged in user
        this.user = response.data.user

        // true_user is the impersonated user
        this.true_user = response.data.true_user

        this.account = response.data.account

        useUserStore().setUser(this.user)
        useUserStore().setTrueUser(this.true_user)

        setPendo(this.account, this.user)
        setKoala(this.account, this.user)

        return {
          account: this.account,
          user: this.user,
          true_user: this.true_user,
        }
      })
    },
  },
})
