<template lang="html">
  <modal name="share_asset_modal_new" @before-open="beforeOpen" height="auto" width="1200px">
    <div class="modal_container modal_container--share_asset_modal_new" v-if="contentAsset">
      <div class="modal_header pb-1">
        <h1 class="title mb-0">Download {{ this.assetTypeText() }} Asset</h1>
        <div class="closer" @click="$modal.hide('share_asset_modal_new')">
          <TimesIcon></TimesIcon>
        </div>
      </div>

      <div class="modal_body d-flex pt-4">
        <section class="sharing_options d-flex flex-column">
          <div class="settings pb-4">
            <p class="header">Settings</p>
          </div>

          <div class="visual_theme_options pb-2">
            <p class="header pb-1">Visual Theme</p>
            <section>
              <b-form-group>
                <b-form-select v-model="design" :options="visualThemes"></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="stat_color_options pb-2">
            <p class="header pb-1">Infographic Color</p>
            <section>
              <b-form-group>
                <b-form-select v-model="statColor" :options="basicOptions"></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="background_color pb-2" v-if="design == 'branded'">
            <p class="header pb-1">Background Color</p>
            <section>
              <b-form-group>
                <b-form-select
                  v-model="backgroundColor"
                  :options="backgroundColorOptions"
                ></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="file_type_options pb-2">
            <p class="header pb-2">File Type</p>
            <b-form-group>
              <b-form-radio name="filetype-radios" v-model="filetype" value="png">PNG</b-form-radio>
              <b-form-radio name="filetype-radios" v-model="filetype" class="mt-2" value="pdf"
                >PDF</b-form-radio
              >
            </b-form-group>
          </div>
        </section>

        <div class="asset_preview mr-0 pl-4">
          <section v-if="contentAsset.type == 'StatAsset'">
            <StatBasic
              :contentAsset="contentAsset"
              :statColorOption="statColor"
              v-if="design == 'basic'"
            >
            </StatBasic>

            <StatBranded
              :contentAsset="contentAsset"
              :statColorOption="statColor"
              :backgroundColorOption="backgroundColor"
              v-if="design == 'branded'"
            />
          </section>

          <section v-if="contentAsset.type == 'ChartAsset'">
            <BarChartBasic
              :contentAsset="contentAsset"
              :chartColorOption="statColor"
              :account="contentAsset.account"
              v-if="design == 'basic'"
            >
            </BarChartBasic>

            <BarChartBranded
              :contentAsset="contentAsset"
              :chartColorOption="statColor"
              :backgroundColorOption="backgroundColor"
              v-if="design == 'branded'"
            />
          </section>

          <section v-if="contentAsset.type == 'MatrixxChart'">
            <!-- @TODO: Move ShareMatrixxChartModal here -->
          </section>

          <section v-if="contentAsset.type == 'RenderableTestimonial'">
            <!-- @TODO: Add RenderableTestimonial here -->
          </section>
        </div>
      </div>

      <div class="modal_footer_new d-flex justify-content-between py-3 px-4">
        <CopyEmbedCodeNew
          v-if="contentAsset.published || contentAsset.public"
          :snippet="this.embedSnippet()"
          class="mr-3"
        ></CopyEmbedCodeNew>
        <CopyShareLinkNew
          v-if="contentAsset.published || contentAsset.public"
          :ueId="contentAsset.identifier"
          @copied="trackCopy"
        />

        <ButtonNew
          @click="onDownload"
          size="large"
          class="download_button rounded mr-0 ml-auto"
          v-if="!downloadInProgress"
        >
          <i class="bi bi-download" />
          Download Asset
        </ButtonNew>
      </div>
    </div>
  </modal>
</template>
<script lang="ts">
import TimesIcon from './graphics/TimesIcon.vue'
import CopyEmbedCodeNew from '@components/CopyEmbedCodeNew.vue'
import CopyShareLinkNew from '@components/CopyShareLinkNew.vue'
import StatBasic from '@components/StatBasic'
import BarChartBasic from '@components/BarChartBasic'
import StatBranded from '@components/StatBranded'
import BarChartBranded from '@components/BarChartBranded'
import ButtonNew from '@components/new_components/ButtonNew.vue'
import { pendoTrack } from '@lib/pendo'
import { amplitudeTrack } from '@lib/amplitude'
import { downloadContentAsset } from '@app/api.js'
import { AxiosResponse } from 'axios'
import ahoy from 'ahoy.js'
import { assetEventData } from '@lib/tracking'

export default {
  components: {
    TimesIcon,
    CopyEmbedCodeNew,
    CopyShareLinkNew,
    ButtonNew,
    StatBranded,
    StatBasic,
    BarChartBasic,
    BarChartBranded,
  },
  data() {
    return {
      filetype: 'png',
      downloadInProgress: false,
      matrixxChart: null,
      account: null,
      design: 'basic',
      statColor: 'primary',
      backgroundColor: 'primary',
      visualThemes: [
        {
          value: 'basic',
          text: 'Basic',
        },
        {
          value: 'branded',
          text: 'Branded',
        },
      ],
      basicOptions: [
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
      ],
      backgroundColorOptions: [
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
        {
          value: 'both',
          text: 'Primary and Secondary',
        },
        {
          value: 'dark',
          text: 'Dark',
        },
      ],
      contentAsset: null,
    }
  },
  computed: {
    downloadOptions() {
      return {
        filetype: this.filetype,
        // We use `design` here to keep it consistent
        // with the Render components and AssetPresenter
        design: this.design,
        stat_color_option: this.statColor,
        background_color_option: this.backgroundColor,
      }
    },
    eventData() {
      return {
        ...assetEventData(this.contentAsset),
        filetype: this.filetype,
      }
    },
  },
  methods: {
    // @TODO: this function is a duplicate of the one in ShareMatrixxChartModal.vue
    // move this to a library function
    attemptPopUp(url: string) {
      const new_window = window.open(url, '_blank')
      try {
        new_window.focus()
      } catch (e) {
        // Trying to `window.open` a PDF attachment file seems to trigger pop-up blockers on Chrome
        alert(
          'It looks like a pop-up blocker prevented the download window from opening. Please allow them for this site.',
        )
      }
    },
    assetTypeText() {
      switch (this.contentAsset.type) {
        case 'ChartAsset':
          return 'Chart'
        case 'StatAsset':
          return 'Stat'
        case 'RenderableTestimonial':
          return 'Testimonial'
        case 'CustomerSpotlight':
          return 'Customer Spotlight'
        case 'SurveySpotlightAsset':
          return 'Survey Spotlight'
        default:
          return null
      }
    },
    assetTypeEmbedName() {
      switch (this.contentAsset.type) {
        case 'ChartAsset':
          return 'chart_asset'
        case 'StatAsset':
          return 'stat_asset'
        case 'RenderableTestimonial':
          return 'testimonial'
        default:
          return null
      }
    },
    embedSnippet() {
      const srcUrl =
        location.protocol +
        '//' +
        location.host +
        `/render/${this.assetTypeEmbedName()}/` +
        `${this.contentAsset.identifier}?` +
        new URLSearchParams(this.downloadOptions).toString()
      return `<iframe src='${srcUrl}' frameBorder='0'></iframe>`
    },
    beforeOpen(e) {
      this.contentAsset = e.params.content_asset
    },
    onDownload() {
      this.downloadInProgress = true
      downloadContentAsset(this.contentAsset.id, this.downloadOptions)
        .then((r: AxiosResponse) => {
          pendoTrack('download_asset', this.eventData)
          amplitudeTrack('asset downloaded', this.eventData)
          ahoy.track('asset downloaded', this.eventData)
          return r.data.download_url
        })
        .then((url: string) => this.attemptPopUp(url))
        .catch(() => {
          alert('Darn! Something went wrong. Please try again.')
        })
        .finally(() => (this.downloadInProgress = false))
    },
    trackCopy() {
      ahoy.track('asset share link copied', this.eventData)
    },
  },
}
</script>
<style lang="sass" scoped>
.modal_container--share_asset_modal_new
  font-size: 1em

  &.modal_container .modal_header
    .title
      font-size: 1.75em
      font-weight: 700
  &.modal_container .asset_preview
    width: 60%
    height: 100%
  .sharing_options
    width: 40%
    gap: 10px
    .settings .header
      font-size: 1.25em
    .header
      font-size: 1em
      font-weight: 700

.modal_container--share_asset_modal_new
  &.modal_container .modal_footer_new
    border-top: 1px solid $border-color
</style>
