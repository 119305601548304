export function validateYouTubeUrl(url: string): boolean {
  if (!url || url == '') return false

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length == 11) {
    return true
  }
  return false
}

export function validateVimeoUrl(url: string): boolean {
  if (!url || url == '') return false

  const regExp = /^(https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/
  const match = url.match(regExp)
  if (match) {
    return true
  }
  return false
}

const YOUTUBE_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

const VIMEO_REGEX = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/

// Determine whether this link is for youtube, vimeo,
// or neither.
// Returns 'youtube', 'vimeo', or null
export function getVideoSource(url: string) {
  if (!url) return null
  if (url.includes('youtube.com') || url.includes('youtu.be')) return 'youtube'
  if (url.includes('vimeo.com')) return 'vimeo'
  return null
}

export function getYoutubeId(url: string) {
  if (!url) return null
  const match = url.match(YOUTUBE_REGEX)
  return match ? match[1] : null
}

export function getVimeoId(url: string) {
  if (!url) return null
  const match = url.match(VIMEO_REGEX)
  if (match) {
    return match[2]
  }
  return null
}

export function youtubePreviewUrl(url: string) {
  const id = getYoutubeId(url)
  if (id) {
    return `https://img.youtube.com/vi/${id}/0.jpg`
  }
  return null
}

export function vimeoPreviewUrl(url: string) {
  const id = getVimeoId(url)
  if (id) {
    return `https://vumbnail.com/${id}.jpg`
  }

  return null
}

// Generates a youtube or vimeo preview url for the provided video url
// If the provided url is not a valid youtube or vimeo url, just return null.
export function getPreviewUrl(url: string) {
  const source = getVideoSource(url)
  if (source === 'youtube') return youtubePreviewUrl(url)
  if (source === 'vimeo') return vimeoPreviewUrl(url)
  return null
}

// Return a regex which uses regex OR to combine regexes
// for all the supported video types. Result is a regex that can
// answer the question "is this a video URL for a supported platform".
export function generateComprehensiveRegex() {
  return new RegExp(YOUTUBE_REGEX.source + '|' + VIMEO_REGEX.source)
}
