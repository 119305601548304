/* set up pendo tracking.  This function should be called to set the logged in user (or recipient) and account. */
export const setPendo = (account, user, type = 'Customer') => {
  if (typeof window.pendo === 'undefined') return

  const roles = {}

  const userRoles = user.account_roles || []

  userRoles.forEach((role, index) => {
    roles[`role${index + 1}`] = role
  })

  const pendoData = {
    visitor: {
      ...roles,
      id: user.email,
      email: user.email,
      full_name: user.first_name + ' ' + user.last_name,
      type: type,
    },

    account: {
      id: account.name,
      name: account.name,
      creationDate: account.created_at,
    },
  }

  window.pendo.initialize(pendoData)
}

/* Track a user event in Pendo.  This function should be called to track events in Pendo. */
export const pendoTrack = (event, properties) => {
  if (typeof window.pendo === 'undefined') return

  window.pendo.track(event, properties)
}
