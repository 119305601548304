<template lang="pug">
  .demo_button
    button.button.button-primary(title='Schedule A Demo' @click='$modal.show("schedule_meeting_modal")' v-if='large')
      span.button-icon
        svg(width='16' height='16' viewbox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg')
          title Calendar Icon
          path(d='M5 1V3H11V1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
          rect.hover-fill(x='2' y='3' width='12' height='11' rx='2' stroke-width='2' stroke-linejoin='round')
          path.hover-cutaway(d='M1 7H15' stroke-width='2' stroke-linejoin='round')
      | Schedule A Demo

    button.text-link.navbar-link.button-navbar(aria-label='Schedule A Demo' title='Schedule A Demo' @click='$modal.show("schedule_meeting_modal")' v-else) Schedule A Demo
</template>
<script>
export default {
  name: 'DemoButton',
  props: ['large'],
}
</script>
