<template>
  <div v-if="contentAssets.length > 0" class="assets">
    <router-link
      v-for="asset in contentAssets"
      :key="asset.id"
      class="asset mb-4"
      :to="assetUrl(asset)"
    >
      <div class="clicky" @click="setCookie(asset)">
        <FileAsset v-if="asset.type == 'FileAsset'" :file-asset="asset" renderAsList />
        <VideoLinkAsset
          v-else-if="asset.type == 'VideoLinkAsset'"
          :video-link-asset="asset"
          renderAsList
        />
        <TestimonialBasic
          v-else-if="asset.type == 'RenderableTestimonial'"
          :renderable-testimonial="asset"
          :account="account"
        />
        <BarChartBasic
          v-else-if="asset.type == 'ChartAsset'"
          :content-asset="asset"
          :account="account"
        />
        <StatBasic v-else-if="asset.type == 'StatAsset'" :content-asset="asset" />
        <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset" />
        <MatrixxChartBasic
          v-else-if="asset.type == 'MatrixxChart'"
          :account="account"
          :matrixxChart="asset"
        />
        <SpotlightPreview
          v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
          :content_asset="asset"
        />
      </div>
      <div v-if="withShareButton" class="controls mr-4 mt-2">
        <div class="share text-right" @click.stop="shareContentAsset(ali.content_asset)">
          <BIconShare></BIconShare>
        </div>
      </div>
    </router-link>
  </div>
  <div v-else class="assets none">
    <h3>No assets match those filters.</h3>
  </div>
</template>

<script>
import FileAsset from '@components/FileAsset'
import VideoLinkAsset from '@components/VideoLinkAsset'
import TestimonialBasic from '@components/TestimonialBasic'
import BarChartBasic from '@components/BarChartBasic'
import StatBasic from '@components/StatBasic'
import Microsite from '@app/components/Microsite'
import SpotlightPreview from '@components/SpotlightPreview'
import MatrixxChartBasic from '@components/MatrixxChartBasic'

export default {
  components: {
    FileAsset,
    VideoLinkAsset,
    TestimonialBasic,
    BarChartBasic,
    StatBasic,
    Microsite,
    SpotlightPreview,
    MatrixxChartBasic,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    contentAssets: {
      type: Array,
      default: () => [],
    },
    withShareButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    assetUrl(asset) {
      return `/assets/${asset.identifier}`
    },
    setCookie(asset) {
      this.$emit('asset-clicked', asset)
    },
  },
}
</script>
<style lang="sass" scoped>
.assets
  column-count: auto
  column-width: 370px
.assets.none
  min-height: 35vh
.asset
  text-decoration: none
  display: inline-block
  width: 100% !important

.controls
  height: 40px
  .share
    cursor: pointer
    display: none
    color: grey

.asset:hover
  text-decoration: none
  .controls
    .share
      display: block !important
      &:hover
        color: black
</style>
