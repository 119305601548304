<template lang="pug">
  section.block.key-stat-block
    span.key-stat-block__qualifier(v-if="qualifier === '$'") {{qualifier}}
    h1.key-stat-block__number {{number}}
    span.key-stat-block__qualifier(v-if="qualifier !== '$'") {{qualifier}}
    span.key-stat-block__tagline {{ tagline }}
</template>
<script lang="ts">
export default {
  components: {},
  props: ['number', 'qualifier', 'tagline'],
  data() {
    return {}
  },
  // computed: {
  //   some_cumputed() {}
  // },
}
</script>
<style lang="sass">
.key-stat-block

.key-stat-block__number
  display: inline-block
  font-size: 3.5em!important
  font-weight: 800

.key-stat-block__qualifier
  color: var(--accent-color)
  font-size: 2em
  font-weight: 200

.key-stat-block__tagline
  font-size: 1.25em
  font-weight: bold
  margin-left: .25em

@media screen and (max-width: 1200px)
  .key-stat-block__number
    font-size: 3em!important
  .key-stat-block__qualifier
    font-size: 1.25em
  .key-stat-block__tagline
    font-size: .75em
</style>
